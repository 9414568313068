<template>
  <el-container class="container">
    <el-header>
      <h1>文案管理端</h1>
      <el-dropdown>
        <span class="el-dropdown-link">
          {{ userinfo.login }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="out" @click.native="logout">退出账号</el-dropdown-item>
          <el-dropdown-item command="out" @click.native="reset">修改密码</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-header>
    <el-container>
      <el-aside width="200px">
        <el-menu class="el-menu-vertical-demo" :default-active="$route.path" router>
          <el-menu-item index="/customer" title="用户管理">
            <i class="el-icon-s-custom"></i>
            <span slot="title">用户管理</span>
          </el-menu-item>
          <el-menu-item index="/recycleBin" title="文案管理">
            <i class="el-icon-document-copy"></i>
            <span slot="title">文案管理</span>
          </el-menu-item>
          <el-menu-item index="/banner" title="banner管理">
            <i class="el-icon-picture"></i>
            <span slot="title">banner管理</span>
          </el-menu-item>
          <!-- <el-menu-item index="/advertisment" title="banner管理">
            <i class="el-icon-tickets"></i>
            <span slot="title">专题管理</span>
          </el-menu-item> -->
          <el-menu-item index="/shipManage" title="分类管理">
            <i class="el-icon-s-unfold"></i>
            <span slot="title">分类管理</span>
          </el-menu-item>
          <el-menu-item index="/license" title="营销日历">
            <i class="el-icon-date"></i>
            <span slot="title">营销日历</span>
          </el-menu-item>
          <el-menu-item index="/user" title="客服管理">
            <i class="el-icon-user-solid"></i>
            <span slot="title">系统管理员</span>
          </el-menu-item>
          <el-menu-item index="/order" title="订单信息">
            <i class="el-icon-s-order"></i>
            <span slot="title">订单信息</span>
          </el-menu-item>
          <el-menu-item index="/activity" title="活动管理">
            <i class="el-icon-s-order"></i>
            <span slot="title">活动管理</span>
          </el-menu-item>
          <!-- <el-submenu index="0" v-if="ruleList.includes('order')">
            <template slot="title">
              <i class="el-icon-s-order"></i>
              <span>订单信息</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/order?type=1" title="订单信息" v-if="ruleList.includes('order1')">产装订单</el-menu-item>
              <el-menu-item index="/order?type=2" title="订单信息" v-if="ruleList.includes('order2')">内装订单</el-menu-item>
              <el-menu-item index="/recycleBin" title="订单回收站" v-if="ruleList.includes('recycleBin')">订单回收站</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="1" v-if="ruleList.includes('ziliao')">
            <template slot="title">
              <i class="el-icon-s-check"></i>
              <span>资料管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/customer" title="客户管理" v-if="ruleList.includes('customer')">客户管理</el-menu-item>
              <el-menu-item index="/shipManage" title="船公司管理" v-if="ruleList.includes('shipManage')">船公司管理</el-menu-item>
              <el-menu-item index="/license" v-if="ruleList.includes('license')">工厂管理</el-menu-item>
              <el-menu-item index="/address" v-if="ruleList.includes('address')">产装地址管理</el-menu-item>
              <el-menu-item index="/cost" v-if="ruleList.includes('cost')">堆场/仓库管理</el-menu-item>
              <el-menu-item index="/Partner" v-if="ruleList.includes('Partner')">品名管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3" v-if="ruleList.includes('system')">
            <template slot="title">
              <i class="el-icon-s-tools"></i>
              <span>系统设置</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/settingCompany" v-if="ruleList.includes('settingCompany')">角色管理</el-menu-item>
              <el-menu-item index="/setting" v-if="ruleList.includes('setting')">个人信息</el-menu-item>
              <el-menu-item index="/user" v-if="ruleList.includes('user')">用户管理</el-menu-item>
              <el-menu-item index="/userMember" v-if="ruleList.includes('userMember')">会员管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu> -->
        </el-menu>
      </el-aside>
      <el-main>
        <!-- <el-tabs :value="editableTabsValue" type="card" closable>
          <el-tab-pane v-for="tab in $store.state.tabs" :key="tab.name" :label="tab.name">
          </el-tab-pane>
        </el-tabs> -->
        <tabComponet></tabComponet>
        <keep-alive>
          <div style="background-color: white">
            <router-view />
          </div>

        </keep-alive>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import tabComponet from '@/components/tabComponets.vue';
export default {
  name: 'HomeView',
  components: {
    tabComponet
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit('addTab', { name: to.name, title: to.meta.title });
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('removeTab', from.name);
    next();
  },
  data: function () {
    return {
      // userinfo: {},
      ruleList: []
    }
  },
  computed: {
    userinfo() {
      return this.$store.state.userInfo
    }
  },
  mounted() {
    document.querySelector('.container').style.height = document.documentElement.clientHeight + 'px';
  },
  methods: {
    async logout() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          localStorage.removeItem('tags')
          this.$router.push('/login')
        })
      }).catch(() => { });
    },
    reset() {
      this.$router.push('/setting')
    }
  }
}
</script>

<style>
.el-header {
  color: #fff;
  text-align: center;
  background: #2468a9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-aside {
  color: #333;
  height: 100%;
}

.el-main {
  color: #333;
}

.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>
